import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { FC, ReactNode, memo } from 'react'
import { MdFlag, MdKeyboardArrowDown } from 'react-icons/md'

import {
  QaQuestionnaireListItem,
  usePublishedQuestionnaires,
} from 'features/questionnaire'
import { selectQuestionnaire } from '../../Questionnaire'

import AnimatedCheckmark from './Checkmark.svg'
import CollapsibleButton from './CollapsibleButton'

interface QAQuestionnaireMenuProps {
  qaIsReviewed: boolean
  onToggleIsReviewed: () => void
  reviewedByUserText: ReactNode
}

export const QAQuestionnaireMenu: FC<QAQuestionnaireMenuProps> = memo(
  ({ qaIsReviewed, onToggleIsReviewed, reviewedByUserText }) => {
    const { questionnaires } = usePublishedQuestionnaires()

    const handleSelectQuestionnaire = (
      questionnaireListItem: QaQuestionnaireListItem,
    ) => {
      selectQuestionnaire(questionnaireListItem.uid)
    }

    if (!questionnaires || questionnaires.length === 0) {
      return null
    }

    return (
      <Flex alignItems="center" gap={0}>
        <CollapsibleButton
          borderRightRadius={questionnaires.length > 0 ? 0 : 'md'}
          icon={qaIsReviewed ? AnimatedCheckmark : MdFlag}
          iconSize={qaIsReviewed ? '22px' : undefined}
          label={
            questionnaires.length > 0
              ? ''
              : qaIsReviewed
                ? t`Reviewed`
                : t`Review`
          }
          onClick={onToggleIsReviewed}
          px={0}
          size="xs"
          tooltip={reviewedByUserText}
        />
        {questionnaires.length > 0 && (
          <>
            <Box h="55%" w="1px" background="gray.200" />

            <Menu isLazy>
              <MenuButton
                as={Button}
                borderLeftRadius={0}
                px={1}
                size="xs"
                variant="ghost"
              >
                <Flex align="center">
                  <Text mr={1}>{qaIsReviewed ? t`Reviewed` : t`Review`}</Text>
                  <Icon as={MdKeyboardArrowDown} color="gray.600" />
                </Flex>
              </MenuButton>
              <MenuList>
                {questionnaires.map((questionnaire) => (
                  <MenuItem
                    key={questionnaire.uid}
                    onClick={() => handleSelectQuestionnaire(questionnaire)}
                  >
                    <Tooltip
                      label={questionnaire.title}
                      isDisabled={questionnaire.title.length < 80}
                    >
                      <Text>{questionnaire.title.slice(0, 80)}</Text>
                    </Tooltip>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </>
        )}
      </Flex>
    )
  },
)
